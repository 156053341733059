import React, { useEffect, useState } from "react"
import { TailSpin } from "react-loader-spinner"

import utils from "../../utils/utils"

import Envelopes from './Envelopes'
import PackageEdit from './PackageEdit'
import ShareDocuments from '../AddEnvelope/ShareDocuments'

const Packages = ({
  canEditGroups,
  groupOptions,
  isAdmin,
  isEmbedded = false,
  origin,
  packageData: initialPackageData,
  requestCenters,
}) => {
  const [packageData, setPackageData] = useState(initialPackageData)
  const [envelopes, setEnvelopes] = useState([])
  const [loading, setLoading] = useState(true)
  const [shareData, setShareData] = useState(false)

  const loadPackage = () => {
    fetch(`/packages/${packageData.slug}.json`, {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'X-CSRF-Token': utils.csrfToken()
      }
    })
      .then(response => response.json())
      .then(packageData => {
        setPackageData(packageData)
        setEnvelopes(
          packageData.envelopes.sort((envelope_a, envelope_b) => {
            const dateA = new Date(envelope_a.created_at)
            const dateB = new Date(envelope_b.created_at)
            return dateB - dateA
          })
        )
        setLoading(false)
      })
  }

  const loadShareData = () => {
    if (!isAdmin) {
      return
    }

    fetch(`/packages/${packageData.slug}/share_envelope_data.json`, {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'X-CSRF-Token': utils.csrfToken()
      }
    })
      .then(response => response.json())
      .then(data => setShareData(data))
  }

  useEffect(() => {
    loadShareData()
    loadPackage()
  }, [])

  const onEnvelopeAdded = () => {
    loadShareData()
    loadPackage()
  }

  const onEnvelopeRemove = (envelopeIndex) => {
    loadShareData()
    setEnvelopes(envelopes.filter((_envelope, index) => {
      return index != envelopeIndex
    }))
  }

  if (isEmbedded) {
    return <>
      <div className="content p-0" style={{ border: 0 }}>
        <div className="columns">
          <div className="column">
            <strong>{packageData.name}</strong>
          </div>
        </div>
      </div>
      <div>
        {!loading ? <Envelopes
          canEdit={isAdmin}
          envelopes={envelopes}
          onEnvelopeRemove={() => { }}
          origin={origin}
          packageData={packageData} /> :
          <div className="is-flex is-align-items-center is-justify-content-center">
            <TailSpin color="#18242d" height={24} width={24} />
          </div>}
      </div>
    </>
  } else {
    return <>
      <div className="content is-title-block">
        {packageData.name}
        {isAdmin && <PackageEdit
          canEditGroups={canEditGroups}
          groupOptions={groupOptions}
          packageData={packageData} onSave={(packageData => setPackageData(packageData))}
          requestCenters={requestCenters} />}
      </div>
      <div className="content has-dark-background">
        <div className="columns">
          <div className="column pb-5">
            <h1>{packageData.name}</h1>
            {packageData.description && <div style={{ marginTop: '10px' }}>{packageData.description}</div>}
          </div>
        </div>
      </div>
      <div>
        <div>
          <ShareDocuments
            agreementResources={shareData.serialized_agreement_resources}
            availableAgreementResources={shareData.serialized_available_agrement_resources}
            documentResources={shareData.serialized_available_document_resources}
            buttonTitle="Add Envelope"
            loaded={!!shareData}
            onEnvelopeAdded={onEnvelopeAdded}
            packageData={packageData}
            planAllowsLabels={shareData.plan_allows_labels}
            title="Envelopes" />
        </div>
        <div>
          {!loading ? <Envelopes
            canEdit={isAdmin}
            envelopes={envelopes}
            onEnvelopeRemove={onEnvelopeRemove}
            origin={origin}
            packageData={packageData}
            shareData={shareData} /> :
            <div className="is-flex is-align-items-center is-justify-content-center">
              <TailSpin color="#18242d" height={24} width={24} />
            </div>}
        </div>
      </div>
    </>
  }
}

export default Packages