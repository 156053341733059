import React, { useMemo } from 'react'
import Envelope from './Envelope'

const Envelopes = ({
  canEdit,
  envelopes,
  onEnvelopeRemove,
  origin,
  packageData,
  shareData,
}) => {
  const envelopeList = useMemo(() => {
    if (!envelopes || envelopes.length == 0) {
      return <p style={{ fontSize: '22px' }} className="has-text-centered">
        <br />
        There are no files in this package.
        <br />
        Click Add Envelope to add documents or agreements.
        <br />
        <br />
      </p>
    }

    return envelopes.map((envelope, index) => (
      <Envelope
        canEdit={canEdit}
        createdAt={envelope.created_at}
        envelopeIndex={index}
        agreementResources={envelope.agreement_resources}
        documentResources={envelope.document_resources}
        key={`package-envelope-${index}`}
        onRemove={onEnvelopeRemove}
        origin={origin}
        packageData={packageData}
        shareData={shareData} />
    ))
  }, [envelopes])

  return <div className="content has-no-border-bottom">
    <div className="columns">
      <div className="column">
        {envelopeList}
      </div>
    </div>
  </div>
}

export default Envelopes
