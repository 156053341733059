import React, { useEffect, useRef, useState } from "react"

const SearchBox = ({
  largeBox = false,
  onFocusChange = () => {},
  onSearchExpendChange = () => {},
  onChange = () => { }
}) => {
  const showSearchBoxSize = largeBox ? 622 : 350
  const searchInputRef = useRef();
  const [searchValue, setSearchValue] = useState(null)
  const [showSearch, setShowSearch] = useState(false)

  useEffect(() => {
    onChange(searchValue)
  }, [searchValue])

  useEffect(() => {
    onFocusChange(showSearch)
  }, [showSearch])

  const updateShowSearch = (val) => {
    setShowSearch(val)
    setTimeout(() => {
      onSearchExpendChange()
    }, 10)
  }

  return <div style={{ display: 'flex', position: 'relative' }}>
    <svg style={{ position: 'absolute', zIndex: 10, top: '6px', left: '10px', opacity: showSearch ? '1' : '0.5' }}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24">
      <path fill="#424C53" fillRule="evenodd" d="M15.5,14 L14.71,14 L14.43,13.73 C15.41,12.59 16,11.11 16,9.5 C16,5.91 13.09,3 9.5,3 C5.91,3 3,5.91 3,9.5 C3,13.09 5.91,16 9.5,16 C11.11,16 12.59,15.41 13.73,14.43 L14,14.71 L14,15.5 L19,20.49 L20.49,19 L15.5,14 Z M9.5,14 C7.01,14 5,11.99 5,9.5 C5,7.01 7.01,5 9.5,5 C11.99,5 14,7.01 14,9.5 C14,11.99 11.99,14 9.5,14 Z" />
    </svg>
    <input
      autoComplete="off"
      className="input"
      type="text"
      name="search-input"
      placeholder="Search..."
      onFocus={() => { updateShowSearch(true) }}
      onChange={(e) => setSearchValue(e.target.value)}
      style={{ paddingLeft: '38px', height: '36px', width: showSearch ? `${showSearchBoxSize}px` : '200px' }}
      ref={searchInputRef} />
    {showSearch && <a style={{ marginLeft: '16px', lineHeight: '36px', color: '#18242D' }} onClick={() => {
      updateShowSearch(false)
      setSearchValue(null)   
      searchInputRef.current.value = ''
    }}>Cancel</a>}
  </div>
}

export default SearchBox