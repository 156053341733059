import React, { useEffect, useState } from "react"

import LabelList from "../../LabelList"

const LabelFilter = ({ 
  onFilterUpdate = (selectedLabelIds) => {},
  labels,
  listName,
  right,
}) => {
  const [selectedLabelIds, setSelectedLabelIds] = useState(() => {
    const getSelectedLabelIds = () => {
      const urlSearchParams = new URLSearchParams(window.location.search)
      const urlParams = Object.fromEntries(urlSearchParams.entries())
      
      let labelIds
      if (urlParams['labels']) {
        labelIds = urlParams['labels'].split(",").map(str => parseInt(str))
      } else {
        const storedValue = localStorage.getItem(listName)
        labelIds = JSON.parse(storedValue)
      }
    
      return labelIds
    }

    return getSelectedLabelIds() || ['all']
  })

  useEffect(() => {
    if (listName) {
      localStorage.setItem(listName, JSON.stringify(selectedLabelIds))
    }

    setLabelsInUrl(selectedLabelIds)
    onFilterUpdate(selectedLabelIds)
  }, [selectedLabelIds])

  const setLabelsInUrl = (selectedLabelIds) => {
    const url = new URL(window.location.href)
    const searchParams = new URLSearchParams(url.search)
    const newSearchParams = new URLSearchParams()
    
    for (const [key, value] of Object.entries(searchParams)) {
      if (key !== 'labels') {
        newSearchParams.set(key, value)
      }
    }

    if (selectedLabelIds.length && selectedLabelIds[0] !== 'all') {
      newSearchParams.set('labels', selectedLabelIds)
    }

    url.search = newSearchParams.toString()

    window.history.replaceState(null, null, url.toString())
  }  

  return <LabelList
    addAllOption={true}
    initialLabels={[{id: 'all', name: 'All' }, ...labels]}
    onLabelSelectionChange={labelIds => setSelectedLabelIds(labelIds)}
    right={right}
    selectedLabelIds={selectedLabelIds}
    withBorderBottom={false} />
}

export default LabelFilter
