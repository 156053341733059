import React, { useMemo, useState } from "react"

import LoaderButton from 'components/shared/LoaderButton'

import { removeEnvelope } from "./utils"

import Modal from "../../Modal"

const EnvelopeRemoveModal = ({
  onCancel = () => { },
  onRemove = () => { },
  agreementResources,
  documentResources,
  packageData
}) => {
  const [deleting, setDeleting] = useState(false)

  const submitEnvelopeRemoval = () => {
    setDeleting(true)

    removeEnvelope({
      packageSlug: packageData.slug,
      agreementResourceIds: agreementResources.map((resource) => resource.id),
      documentResourceIds: documentResources.map((resource) => resource.id),
    }).then(() => onRemove())
      .finally(() => setDeleting(false))
  }

  const confirmButton = useMemo(() => {
    if (deleting) {
      return <LoaderButton variant="is-danger" width="80px" />
    }

    return <a
      className="button is-danger"
      draggable="false"
      onClick={() => submitEnvelopeRemoval()}
      style={{ width: '80px' }}>
      Remove
    </a>
  }, [deleting])

  return <Modal
    cancelAction={() => onCancel()}
    confirmButton={confirmButton}
    title="Remove Envelope"
  >
    <div className="columns">
      <div className="column">
        Are you sure?
      </div>
    </div>
  </Modal>
}

export default EnvelopeRemoveModal
