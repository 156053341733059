import React, { useMemo } from "react"
import { useForm } from "react-hook-form"

import blocklist from 'components/CompanyForm/blocklist'
import TextField from "components/inputs/TextField"

import styles from "./style.module"
import Packages from './Packages'

const RequestForm = ({
  companyLogo,
  companyName,
  companySlug,
  edit = false,
  isOwnRequestForm = false,
  onRequestFormChange = (requestForm) => { },
  requestForm,
}) => {
  const blocklistedEmailDomains = useMemo(() => blocklist, [])
  const { register, handleSubmit, errors, formState, reset } = useForm({ mode: "onChange" })

  const submitForm = () => {
    // const form = document.querySelector("#request-form")
    // form.setAttribute("method", "post")
    // form.submit()
  }

  return <div className="content has-dark-background">
    <div className={styles.requestForm}>
      <img src={companyLogo} alt={`${companyName} Logo`} className={`is-block ${styles.requestFormLogo}`} />
      <br />
      <div className="has-text-centered">
        Provide your information and choose documents <br />and agreements to receive.<br /><br />
      </div>

      <form id="request-form" onSubmit={handleSubmit(submitForm)}>
        <div className={styles.requestPackageFormWrapper}>
          <div className="columns">
            <div className="column">
              <TextField
                errors={errors}
                label="First Name"
                name="first_name"
                placeholder="E.g., Susan"
                reff={register({ required: true })} />
            </div>
          </div>
          <div className="columns">
            <div className="column">
              <TextField
                errors={errors}
                label="Last Name"
                name="last_name"
                placeholder="E.g., Smith"
                reff={register({ required: true })} />
            </div>
          </div>
          <div className="columns">
            <div className="column">
              <TextField
                errors={errors}
                label="Email"
                name="email"
                placeholder="E.g., email@company.com"
                reff={register({
                  required: true,
                  pattern: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                  validate: {
                    workEmail: value => {
                      if (typeof blocklistedEmailDomains === 'undefined') {
                        return true
                      }
                      const email_domain = value.split('@')[1]
                      return blocklistedEmailDomains.indexOf(email_domain) == -1
                    }
                  }
                }
              )} />
            </div>
          </div>
          <div className="columns">
            <div className="column">
              <TextField
                errors={errors}
                label="Company Name"
                name="company_name"
                placeholder="E.g., Company Inc"
                reff={register({ required: true })} />
            </div>
          </div>

          <Packages
            companySlug={companySlug}
            edit={edit}
            isOwnRequestForm={isOwnRequestForm}
            onRequestFormChange={onRequestFormChange}
            requestForm={requestForm} />

          <div className={styles.separator}></div>

          <div className="columns">
            <div className="column ligned-up-checkbox">
              <label className="pure-material-checkbox is-flex" htmlFor="tos_accepted">
                <input name="tos_accepted" type="hidden" value="0" />
                <input type="checkbox"
                  value="1"
                  name="tos_accepted"
                  ref={register({ required: true })}
                  id="tos_accepted" />
                <span>
                  {companyName} is using <a href="https://pima.app" target="_blank">Pima</a> to share documents securely. I accept Pima’s <a href="https://pima.app/terms-of-service/" target="_blank">Terms of Service</a> and <a href="https://pima.app/security/privacy-policy/" target="_blank">Privacy Policy</a>.
                </span>
              </label>
            </div>
          </div>

          {isOwnRequestForm && requestForm.packages.length > 0 && <p style={{
            backgroundColor: '#f4f5f5',
            borderRadius: '6px',
            marginBottom: '10px',
            padding: '8px 24px',
            width: '100%'
          }}>
            You can't request your own packages
          </p>}

          <div className="columns">
            <div className="column form-submit-with-checkbox-on-right">
              <input className={`button is-info ${formState.isValid ? '' : 'disabled'}`}
                draggable="false"
                disabled={true}
                style={{ width: '100%' }}
                type="submit"
                value="Submit Request" />
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
}

export default RequestForm
