import React, { useMemo, useState } from "react"
import ReactDOM from 'react-dom'

import utils from 'components/utils/utils'

import Entity from './Entity'
import EnvelopeRemoveModal from './EnvelopeRemoveModal'
import ShareDocumentModalForm from '../AddEnvelope/ShareDocumentModalForm'

const Envelope = ({
  agreementResources,
  canEdit,
  envelopeIndex,
  documentResources,
  onEnvelopeAdded,
  onRemove,
  origin,
  packageData,
  shareData,
}) => {
  const [showEditModal, setShowEditModal] = useState(false)
  const [showRemoveModal, setShowRemoveModal] = useState(false)

  const editModal = useMemo(() => {
    if (!showEditModal) {
      return
    }

    const packageDocumentResources = packageData.envelopes[envelopeIndex].document_resources
    const documentResources = packageDocumentResources.concat(shareData.serialized_available_document_resources)
    const packageAgreementResourceIds = packageData.envelopes[envelopeIndex].agreement_resources.map(agreementResource => agreementResource.id.toString())
    const packageDocumentResourceIds = packageDocumentResources.map(packageDocumentResource => packageDocumentResource.id.toString())

    const formOptions = {
      defaultValues: {
        'agreement_resource_ids': packageAgreementResourceIds,
        'document_resource_ids': packageDocumentResourceIds,
      }
    }

    const shareDocumentModalFormAction = () => {
      if (packageAgreementResourceIds.length > 0 && packageDocumentResourceIds.length > 0) {
        return "bind_document_with_agreement"
      } else if (packageDocumentResourceIds.length > 0) {
        return "send_document"
      } else if (packageAgreementResourceIds.length > 0) {
        return "send_agreement"
      }
    }

    const action = shareDocumentModalFormAction()

    return ReactDOM.createPortal(
      <ShareDocumentModalForm
        action={action}
        agreementResources={shareData.serialized_agreement_resources}
        availableAgreementResources={shareData.serialized_available_agrement_resources}
        cancel={() => setShowEditModal(false)}
        documentResources={documentResources}
        editPackage={true}
        formOptions={formOptions}
        onDone={() => setShowEditModal(false)}
        onSave={() => onEnvelopeAdded()}
        packageData={packageData}
        planAllowsLabels={shareData.plan_allows_labels}
        step={action === 'send_agreement' ? 'agreements' : 'documents'} />,
      document.getElementById('modal-container')
    )
  }, [showEditModal])

  const removeModal = useMemo(() => {
    if (!showRemoveModal) {
      return
    }

    return <EnvelopeRemoveModal
      agreementResources={agreementResources}
      documentResources={documentResources}
      onCancel={() => setShowRemoveModal(false)}
      onRemove={() => {
        setShowRemoveModal(false)
        onRemove(envelopeIndex)
      }}
      packageData={packageData} />
  }, [showRemoveModal])

  const agreementResourceList = useMemo(() => {
    if (!agreementResources) {
      return []
    }

    return agreementResources.map(agreementResource => (
      <li key={`agreement-resource-${agreementResource.slug}`}>
        <Entity
          disabled={!agreementResource.slug}
          entity={agreementResource}
          previewUrl={utils.usePreviewCDN(`/agreement_resources/${agreementResource.slug}/preview?resize=60x80`, agreementResource)}
          subtitle={agreementResource.description}
          url={`/agreements/${agreementResource.slug}?${origin || `package=${packageData.slug}`}`} />
      </li>
    ))
  }, [agreementResources])

  const documentResourceList = useMemo(() => {
    if (!documentResources) {
      return []
    }

    return documentResources.map(documentResource => (
      <li key={`document-resource-${documentResource.slug}`}>
        <Entity
          disabled={!documentResource.slug}
          entity={documentResource}
          previewUrl={utils.usePreviewCDN(`/document_resources/${documentResource.slug}/preview?resize=60x80`, documentResource)}
          subtitle={documentResource.description}
          url={`/documents/${documentResource.slug}?${origin || `package=${packageData.slug}`}`} />
      </li>
    ))
  }, [documentResources])

  return <div style={{ marginBottom: '25px' }}>
    <strong>
      {agreementResourceList.length ? `${agreementResourceList.length} Agreement${agreementResourceList.length > 1 ? 's' : ''}` : null}
      {(agreementResourceList.length && documentResourceList.length) ? ', ' : null}
      {documentResourceList.length ? `${documentResourceList.length} Document${documentResourceList.length > 1 ? 's' : ''}` : null}
      {canEdit && <>
        <a className="is-pulled-right" style={{ fontSize: '14px' }} onClick={e => { setShowRemoveModal(true) }}>Remove</a>
        {documentResourceList.length > 0 && <a className="is-pulled-right" style={{ fontSize: '14px', marginRight: '10px' }} onClick={e => { setShowEditModal(true) }}>Edit</a>}
      </>}
    </strong>
    <div className="separator"></div>
    <ul>{agreementResourceList}</ul>
    <ul>{documentResourceList}</ul>
    {editModal}
    {removeModal}
  </div>
}

export default Envelope
