import utils from "../../utils/utils"

export const removeEnvelope = ({packageSlug, agreementResourceIds, documentResourceIds}) => {
  return fetch(`/packages/${packageSlug}/delete_envelope`, {
    method: "PATCH",
    body: JSON.stringify({
      agreement_resource_ids: agreementResourceIds,
      document_resource_ids: documentResourceIds,
    }),
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'X-CSRF-Token': utils.csrfToken()
    }
  })
}
