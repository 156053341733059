import React, { useMemo } from "react"

import Envelope from 'components/Packages/Package/Envelope'
import ErrorContent from './ErrorContent'
import SuccessContent from './SuccessContent'

const ShareResult = ({
  agreements,
  contacts,
  documents,
  packages = [],
  response = { errors: [] },
  title = 'Envelope Sent!'
}) => {
  const agreementText = agreements.length > 0 ? `${agreements.length} agreement${agreements.length > 1 ? 's' : ''}` : ''
  const documentText = documents.length > 0 ? `${documents.length} document${documents.length > 1 ? 's' : ''}` : ''
  const joinText = agreements.length > 0 && documents.length > 0 ? ' and ' : ''

  const contactList = contacts.map((contact) => `${contact.first_name} ${contact.last_name}`)
  const contact1 = contactList.shift()
  const contact2 = contactList.shift()
  const otherContacts = contactList.length > 0 ? `, and ${contactList.length} other${contactList.length > 1 ? 's' : ''}` : false
  const contactText = `${contact1}${contact2 && otherContacts ? ', ' : ''}${contact2 && !otherContacts ? ' and ' : ''}${contact2 ? contact2 : ''}${otherContacts ? otherContacts : ''}`

  const packageResourcesById = useMemo(() => {
    const data = { agreements: {}, documents: {} }
    for (const packageData of packages) {
      for (const agreementResource of packageData.agreement_resources) {
        data.agreements[agreementResource.id] = agreementResource
      }
      for (const documentResource of packageData.document_resources) {
        data.documents[documentResource.id] = documentResource
      }
    }
    return data
  }, [packages])

  const envelopeErrors = useMemo(() => (response.errors.length > 0 ? response.errors.map((error, envelopeIndex) => {
    const agreementResources = []
    const documentResources = []

    for (const agreementResourceId of error.agreement_resource_ids) {
      if (packageResourcesById.agreements[agreementResourceId]) {
        agreementResources.push(packageResourcesById.agreements[agreementResourceId])
      }
    }
    for (const documentResourceId of error.document_resource_ids) {
      if (packageResourcesById.documents[documentResourceId]) {
        documentResources.push(packageResourcesById.documents[documentResourceId])
      }
    }

    return <Envelope
      agreementResources={agreementResources}
      canEdit={false}
      createdAt={false}
      envelopeIndex={envelopeIndex}
      documentResources={documentResources}
      key={envelopeIndex}
      packageData={response} />
  }) : null), [packageResourcesById, packages, response])

  const content = useMemo(() => {
    if (envelopeErrors?.length > 0) {
      return <ErrorContent
        content={"The following envelopes could not be shared because they contain one or more already shared documents. Please share the missing documents manually."}
        paddingTop={0}
        title={title} />
    }

    return <SuccessContent
      content={`You sent ${documentText}${joinText}${agreementText} to ${contactText}.`}
      title={title} />
  }, [envelopeErrors])

  return <>
    {content}

    {envelopeErrors?.length > 0 && <div className="content has-no-border-bottom">
      <div className="columns">
        <div className="column">
          {envelopeErrors}
        </div>
      </div>
    </div>}
  </>
}

export default ShareResult
