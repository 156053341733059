import React, { useEffect, useMemo, useState } from "react"
import { useForm } from 'react-hook-form'

import Modal from "components/Modal"
import utils from 'components/utils/utils'

const documentListStyle = { listStyleType: 'disc', padding: 0, marginLeft: '20px' }

const BulkArchiveModalForm = ({
  cancelAction,
  selectedDocumentResourceIds,
}) => {
  const [archiveStatus, setArchiveStatus] = useState(false)
  const [archiving, setArchiving] = useState(false)

  const { handleSubmit } = useForm({ mode: "onChange" })

  const preCheck = () => {
    fetch(`/bulk_archive_document_resources/precheck.json`, {
      method: 'post',
      body: JSON.stringify({ document_resource_ids: selectedDocumentResourceIds }),
      headers: { "X-CSRF-Token": utils.csrfToken(), 'Content-type': 'application/json' }
    })
      .then(response => response.json())
      .then(data => setArchiveStatus(data.statuses))
  }

  useEffect(() => {
    preCheck()
  }, [])

  const bulkArchive = () => {
    if (readyToArchiveDocCount === 0) {
      cancelAction()
      return
    }

    setArchiving(true)

    fetch(`/bulk_archive_document_resources.json`, {
      method: 'delete',
      body: JSON.stringify({ document_resource_ids: selectedDocumentResourceIds }),
      headers: { "X-CSRF-Token": utils.csrfToken(), 'Content-type': 'application/json' }
    }).then(() => window.location.reload())
  }

  const inUseDocs = useMemo(() => archiveStatus ? archiveStatus.filter(archiveStatus => archiveStatus.errors) : [], [archiveStatus])
  const inUseDocCount = useMemo(() => inUseDocs.length, [archiveStatus])
  const readyToArchiveDocs = useMemo(() => archiveStatus ? archiveStatus.filter(archiveStatus => archiveStatus.ready) : [], [archiveStatus])
  const readyToArchiveDocCount = useMemo(() => readyToArchiveDocs.length, [archiveStatus])

  return (<form onSubmit={handleSubmit(bulkArchive)}>
    <Modal
      cancelAction={cancelAction}
      cancelButton={readyToArchiveDocCount > 0 ? <a className="button is-white" draggable="false" onClick={() => cancelAction()}>Cancel</a> : <></>}
      confirmButton={<input
        className={`button is-info submit-form-on-enter-key-press ${!archiving && archiveStatus ? '' : 'disabled'}`}
        disabled={archiving && archiveStatus}
        draggable="false"
        type="submit"
        value={archiveStatus ? (readyToArchiveDocCount > 0 ? `Archive ${readyToArchiveDocCount} Documents` : 'Close') : `Loading...`} />}
      title="Archive Documents">

        {!archiveStatus && <p>Checking document statuses...</p>}
        {archiveStatus && <>
          {inUseDocCount && <>
            <p style={{ fontWeight: 'bold' }}>The following documents can't be archived:</p>
            <ul>
              {inUseDocs.map(status => <li key={status.name} style={documentListStyle}>
                {status.name} because {status.errors.join(' and ')}
              </li>)}
            </ul>
          </>}
          {readyToArchiveDocCount > 0 && <>
            {inUseDocCount > 0 && <p style={{ fontWeight: 'bold' }}>
              Continue and archive {readyToArchiveDocCount} documents?
            </p>}
            {inUseDocCount === 0 && <p style={{ fontWeight: 'bold' }}>
              Are you sure you want to archive these {readyToArchiveDocCount} documents?
            </p>}
            <ul>
              {readyToArchiveDocs.map(status => <li key={status.name} style={documentListStyle}>
                {status.name}
              </li>)}
            </ul>
            <p>Once archived, you won't be able to view or share them with new companies. Companies that previously received these documents will still have access to their copies.</p>
          </>}
          {readyToArchiveDocCount === 0 && <p style={{ fontWeight: 'bold' }}>
            No documents can be archived at the moment.
          </p>}
        </>}
    </Modal>
  </form>)
}

export default BulkArchiveModalForm
