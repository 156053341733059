import React, { useState } from "react"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPen } from '@fortawesome/free-solid-svg-icons'

import DeleteLabelFrom from "../DeleteLabelForm"
import LabelForm from "../LabelForm"

import styles from './style.module'

const Label = ({ 
  editMode,
  id,
  label,
  onDelete,
  onSelect,
  onUpdate,
  selected
}) => {
  const [editLabel, setEditLabel] = useState(false)
  const [deleteLabelModal, setDeleteLabelModal] = useState(false)

  const onLabelClick = () => {
    if (editMode) {
      setEditLabel(true)
    } else {
      onSelect(label, !selected)
    }
  }

  return <>
    <div 
      className={`${styles.label} ${selected ? styles.selected : ''}`} 
      id={id}
      onClick={onLabelClick}>
      {label.name}
      {editMode && <FontAwesomeIcon icon={faPen} />}
    </div>
    {editLabel && <LabelForm 
      cancelAction={() => setEditLabel(false)}
      label={label} 
      onSave={label => {
        onUpdate(label)
        setEditLabel(false)
      }}
      deleteAction={() => {
        setEditLabel(false)
        setDeleteLabelModal(true)
      }}
      title={"Edit Label"}            
    />}
    {deleteLabelModal && <DeleteLabelFrom 
      cancelAction={() => setDeleteLabelModal(false)}
      label={label}
      onDelete={label => onDelete(label)} />}
  </>
}

export default Label