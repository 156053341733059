import React from "react"

const TextField = ({
  defaultValue,
  disabled,
  customError = null,
  emailDomain,
  errors,
  label,
  name,
  placeholder,
  reff
}) => {

  const fieldId = name.replace('[', '_').replace(']', '_')

  let detectedError = errors && errors[name]

  if (name.indexOf('[') > -1 && name.indexOf(']') > -1) {
    const brokenFieldName = name.split('[')
    const recordType = brokenFieldName[0]
    const recordField = brokenFieldName[1].replace(']', '')

    if (errors && errors[recordType] && errors[recordType][recordField]) {
      detectedError = errors[recordType][recordField]
    }
  }

  let error;
  if (detectedError && detectedError.type == 'required') {
    error = <span className="error">Required</span>
  }
  if (detectedError && detectedError.type == 'pattern') {
    error = <span className="error">{customError ? customError : 'Not a valid email address'}</span>
  }
  if (detectedError && detectedError.type == 'emailDomain') {
    error = <span className="error">Email domain needs to match {emailDomain}</span>
  }
  if (detectedError && detectedError.type == 'emailTaken') {
    error = <span className="error">Email already taken</span>
  }
  if (detectedError && detectedError.type == 'requestCenterNameTaken') {
    error = <span className="error">Name already taken</span>
  }
  if (detectedError && detectedError.type == 'workEmail') {
    error = <span className="error">Must be work email</span>
  }

  return <div className="field">
    <div className="control">
      <label
        className="label"
        htmlFor={fieldId} >
        {label}
      </label>
      <input
        autoComplete="off"
        className={`input ${detectedError ? 'is-danger' : ''}`}
        defaultValue={defaultValue}
        disabled={disabled}
        id={fieldId}
        name={name}
        placeholder={placeholder}
        ref={reff}
        type="text" />
      {error}
    </div>
  </div>
}

export default TextField
