import React, { useState } from "react"

const ReminderSettings = (props) => {
  const reminderDays = props.reminderDays || 3
  const reminderWeeks = props.reminderWeeks || 2

  const [active, setActive] = useState((props.reminderDays + props.reminderWeeks) > 0)

  return <p>
    <label className="pure-material-checkbox" htmlFor="activeToggle" style={{ marginLeft: '-3px' }}>
      <input onChange={() => setActive(!active)}
        checked={active}
        id="activeToggle"
        type="checkbox" value="1" />
      <span></span>
    </label>
    Send automatic reminders
    {!active && <>
      <input type="hidden" name="company[reminder_days]" value="0" />
      <input type="hidden" name="company[reminder_weeks]" value="0" />
    </>}
    {active && <>
      &nbsp;every&nbsp;
            <select defaultValue={reminderDays}
        className=""
        name="company[reminder_days]"
        id="company_reminder_days"
        disabled={!active} readOnly>
        <option value="1">day</option>
        <option value="2">2 days</option>
        <option value="3">3 days</option>
        <option value="4">4 days</option>
        <option value="5">5 days</option>
        <option value="6">6 days</option>
        <option value="7">7 days</option>
      </select>
            &nbsp;for&nbsp;
            <select defaultValue={reminderWeeks}
        className=""
        name="company[reminder_weeks]"
        id="company_reminder_weeks"
        disabled={!active}
        readOnly>
        <option value="1">1 week</option>
        <option value="2">2 weeks</option>
        <option value="3">3 weeks</option>
        <option value="4">4 weeks</option>
      </select>
    </>}
    <br />
    <br />
  </p>
}

export default ReminderSettings
