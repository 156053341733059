import React, { useMemo, useState } from "react"

import BulkWatermark from './BulkWatermark'
import BulkArchive from './BulkArchive'
import EditLabelDropdown from './EditLabelDropdown'

const EditLabel = ({
  documentResources,
  labels,
  selectedDocumentResourceIds
}) => {
  const [showEditLabelDropDown, setShowEditLabelDropDown] = useState(false)

  const cancel = () => {
    document.querySelector('#all-row-select-document-resource-checkbox').click()
  }

  const commonResourceLabelIds = useMemo(() => {
    // get array of resources label ids: [[1,2,3], [1,3], [2,3]]
    const selectedDocumentResourcesLabelIds = documentResources.filter(documentResource => (
      selectedDocumentResourceIds.includes(documentResource.id))
    ).map(documentResource => (
      documentResource.labels.map(label => label.id)
    ))
    // flatten and unique array of resources label ids: [1,2,3]
    let commonResourceLabelIds = selectedDocumentResourcesLabelIds.flat().filter((value, index, self) => self.indexOf(value) === index)
    // array interect commonResourceLabelIds with each resources: output commonResourceLabelIds = [3]
    for (let i = 1; i < selectedDocumentResourcesLabelIds.length; i++) {
      const resource1LabelIds = selectedDocumentResourcesLabelIds[i - 1]
      const resource2LabelIds = selectedDocumentResourcesLabelIds[i]
      const resourceIntersection = resource1LabelIds.filter(resource1LabelId => resource2LabelIds.includes(resource1LabelId))
      commonResourceLabelIds = commonResourceLabelIds.filter(allResourceLabelId => resourceIntersection.includes(allResourceLabelId))
    }
    return commonResourceLabelIds
  }, [documentResources, labels, selectedDocumentResourceIds])

  return <div>
    <div className="is-flex is-justify-content-space-between is-align-items-center">
      <div className="is-flex is-align-items-center">
        <a className="delete light" aria-label="close" draggable="false" onClick={cancel}>Cancel</a>
        <span style={{ color: '#8C9296', fontSize: '14px', fontWeight: '600', paddingLeft: '18px' }}>
          {selectedDocumentResourceIds.length} Selected
        </span>
      </div>
      <div>
        <a
          onClick={() => setShowEditLabelDropDown(!showEditLabelDropDown)}
          style={{ fontSize: '14px', fontWeight: '600', marginRight: '20px' }}>
          Edit Labels
        </a>
        <BulkWatermark selectedDocumentResourceIds={selectedDocumentResourceIds} />
        <BulkArchive selectedDocumentResourceIds={selectedDocumentResourceIds} />
      </div>
    </div>
    {showEditLabelDropDown && <a
      className="modal-background"
      onClick={e => setShowEditLabelDropDown(false)}
      style={{ backgroundColor: 'transparent' }}
    ></a>}
    <EditLabelDropdown
      labels={labels}
      selectedDocumentResourceIds={selectedDocumentResourceIds}
      selectedLabelIds={commonResourceLabelIds}
      show={showEditLabelDropDown} />
  </div>
}

export default EditLabel
