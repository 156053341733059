import React, { useEffect, useMemo, useRef, useState } from "react"

import utils from 'components/utils/utils'

import Label from "./Label"
import LabelForm from "./LabelForm"

import styles from "./style.module"
import labelStyles from "./Label/style.module"

const sumDivWidths = (parentDiv) => {
  let totalWidth = 0
  const childDivs = parentDiv.querySelectorAll('div')

  childDivs.forEach(div => {
    totalWidth += utils.widthOfDiv(div)
  })

  return totalWidth
}

const labelIdPrefix = 'label-'

const LabelList = ({
  addAllOption,
  editMode,
  right,
  initialLabels,
  onLabelSelectionChange,
  selectedLabelIds,
  withBorderBottom
}) => {
  const labelListRef = useRef(null)
  const expendLabelDiv = useRef(null)
  const labelListExpendedDropdownRef = useRef(null)
  const labelListExpendedDropdownLabelListRef = useRef(null)
  const [compactLabelList, setCompactLabelList] = useState(false)
  const [addNew, setAddNew] = useState(false)
  const [labels, setLabels] = useState(initialLabels || [])
  const [widthOfCombinedLabelDivs, setWidthOfCombinedLabelDivs] = useState(-1)
  const [widthOfDivs, setWidthOfDivs] = useState({})
  const [showLabelListExpendedDropdown, setShowLabelListExpendedDropdown] = useState(false)
  const [toggleExpendLabelDivSelectColor, setToggleExpendLabelDivSelectColor] = useState(false)

  useEffect(() => {
    if (initialLabels) {
      return
    }

    fetch('/settings/labels.json')
      .then(res => res.json())
      .then(labels => {
        setLabels(addAllOption ? [{ id: 'all', name: 'All' }, ...labels] : labels)
      })
  }, [])

  const onLabelSelected = (label, selected) => {
    if (label.id == 'all') {
      if (selectedLabelIds.length == 0) {
        return
      }
      onLabelSelectionChange(['all'])
    } else {
      let options = selectedLabelIds.filter(labelId => labelId !== 'all')
      options = selected ? [...options, label.id] : options.filter(labelId => labelId !== label.id)
      if (options.length === 0) {
        options = addAllOption ? ['all'] : []
      }
      onLabelSelectionChange(options)
    }
  }

  const labelList = useMemo(() => (
    labels.sort((a, b) => {
      const nameA = a.name.toUpperCase()
      const nameB = b.name.toUpperCase()
      if (nameB == 'ALL') {
        return 1
      }
      if (nameA < nameB) {
        return -1
      }
      if (nameA > nameB) {
        return 1
      }
      return 0
    }).map((label) => (
      <Label
        id={`${labelIdPrefix}${label.id}`}
        key={label.id}
        label={label}
        editMode={editMode}
        onDelete={deletedLabel => {
          setLabels(labels.filter(label => label.id !== deletedLabel.id))
        }}
        onSelect={(label, selected) => onLabelSelected(label, selected)}
        onUpdate={updatedLabel => {
          setLabels(labels.map(label => (
            label.id === updatedLabel.id ? { ...updatedLabel } : label
          )))
        }}
        selected={selectedLabelIds?.find(selectedLabelId => selectedLabelId === label.id)} />
    ))
  ), [labels, selectedLabelIds])

  const handleResize = () => {
    let expendLabelDivWidth = 0

    if (labelListRef.current) {
      const labelListWidth = utils.widthOfDiv(labelListRef.current)
      const labelDivs = labelListRef.current.querySelectorAll('div')

      if (expendLabelDiv.current) {
        expendLabelDivWidth = utils.widthOfDiv(expendLabelDiv.current)
      }
      
      if (labelListWidth <= widthOfCombinedLabelDivs + 20){
        setCompactLabelList(true)

        let visibleWidth = 0
        let labelListExpendedDropdownPosition = expendLabelDivWidth/2 - 207
        labelDivs.forEach(label => {
          if (expendLabelDiv.current && label.id === expendLabelDiv.current.id) {
            return
          }

          const labelWidth = widthOfDivs[label.id]
          const newVisibleWidthWithAddedLabel = visibleWidth + labelWidth + expendLabelDivWidth + 10

          if (newVisibleWidthWithAddedLabel < labelListWidth) {
            label.style.display = 'block'
            labelListExpendedDropdownPosition += labelWidth
          } else {
            label.style.display = 'none'
          }
          visibleWidth += labelWidth
        })
        
        if (labelListExpendedDropdownRef.current) {
          labelListExpendedDropdownRef.current.style.left = `${labelListExpendedDropdownPosition}px`
        }
      } else {
        labelDivs.forEach(label => label.style.display = 'block') 
        setCompactLabelList(false)
        setShowLabelListExpendedDropdown(false)
        setToggleExpendLabelDivSelectColor(false)
      }
    }
  }

  const hideOrDisplayLabelsInlabelListExpendedDropdown = () => {
    const config = {}
    let selectedLabelInDropdown = false

    const topBarLabelDivs = labelListRef.current.querySelectorAll('div')
    
    topBarLabelDivs.forEach(label => {
      config[label.id] = label.style.display
      const labelInDropdown = config[label.id] == 'none'

      const labelIdString = label.id.replace(labelIdPrefix, '')
      const labelIdNumber = parseInt(labelIdString)
      const labelIsSelected = selectedLabelIds ? selectedLabelIds.includes(isNaN(labelIdNumber) ? labelIdString : labelIdNumber) : false

      if (labelInDropdown && labelIsSelected) {
        selectedLabelInDropdown = true
      }
    })
    
    if (showLabelListExpendedDropdown && labelListExpendedDropdownLabelListRef.current) {
      const expendedListLabelDivs = labelListExpendedDropdownLabelListRef.current.querySelectorAll('div')

      expendedListLabelDivs.forEach(label => {
        label.style.marginBottom = '10px'
        label.style.display = config[label.id] == 'none' ? 'block' : 'none'
      })
    }
    setToggleExpendLabelDivSelectColor(selectedLabelInDropdown)    
  }

  useEffect(() => {
    window.addEventListener('resize', handleResize)
    window.addEventListener('resize', hideOrDisplayLabelsInlabelListExpendedDropdown)

    handleResize()
    hideOrDisplayLabelsInlabelListExpendedDropdown()

    return () => {
      window.removeEventListener('resize', handleResize)
      window.removeEventListener('resize', hideOrDisplayLabelsInlabelListExpendedDropdown)
    }
  }, [handleResize, hideOrDisplayLabelsInlabelListExpendedDropdown])

  useEffect(() => {
    setWidthOfCombinedLabelDivs(sumDivWidths(labelListRef.current))

    const labelDivs = labelListRef.current.querySelectorAll('div')

    labelDivs.forEach(label => {
      const labelWidth = utils.widthOfDiv(label)
      widthOfDivs[label.id] = labelWidth
    })

    setWidthOfDivs(widthOfDivs)
  }, [labels])

  useEffect(() => hideOrDisplayLabelsInlabelListExpendedDropdown(), [showLabelListExpendedDropdown])

  return <>
    <div ref={labelListRef} style={{ position: 'absolute', left: editMode ? '30px' : '20px', right: right || '20px' }}>
      {labelList}
      {compactLabelList && <div 
        className={`${labelStyles.label} ${toggleExpendLabelDivSelectColor ? labelStyles.selected : ''}`} 
        id="label-list-expend-button"
        onClick={() => setShowLabelListExpendedDropdown(!showLabelListExpendedDropdown)}
        ref={expendLabelDiv}>
        ...
      </div>}
      <div style={{ clear: 'both', width: 0, height: 0 }}></div>
    </div>

    {compactLabelList && showLabelListExpendedDropdown && <div 
      className="dropdown is-active"
      ref={labelListExpendedDropdownRef}
      style={{ 
        position: 'absolute', 
        top: '57px',
        width: '280px',
      }}>
      <div className="dropdown-menu" id="dropdown-menu" role="menu">
        <div className="dropdown-content" style={{ backgroundColor: '#FFFFFF', width: '280px' }}>
          <div ref={labelListExpendedDropdownLabelListRef} style={{ padding: '10px'}}>
            {labelList}
          </div>
          <div style={{ clear: 'both' }}></div>
        </div>
      </div>
    </div>}

    {editMode && <div className={`${styles.separator} ${withBorderBottom ? styles.withBorder : ''}`}>
      <br />
      <br />
      <br />
      <a className="button is-primary" onClick={() => setAddNew(true)}>
        Add Label
      </a>
    </div>}
    {addNew && <LabelForm
      cancelAction={() => setAddNew(false)}
      onSave={(newLabel) => {
        setLabels([...labels, newLabel])
        setAddNew(false)
      }}
      title={"Add Label"}
    />}
  </>
}

export default LabelList