import React, { useEffect, useMemo, useState } from "react"
import { useForm } from "react-hook-form"
import { MultiSelect } from "react-multi-select-component"

import utils from "../../utils/utils"
import resourceFormUtils from '../../utils/ResourceFormUtils'

import Modal from "../../Modal"
import GroupSelector from "../../inputs/GroupSelector"
import TextArea from "../../inputs/TextArea"
import TextField from "../../inputs/TextField"

const PackageForm = ({
  canEditGroups,
  groupOptions,
  onCancel = () => { },
  onSave = () => { },
  onDelete = () => { },
  packageData = null,
  requestCenters,
}) => {
  const [isPublic, setIsPublic] = useState(packageData?.public || false)
  const [groups, setGroups] = useState(packageData?.groups || [])
  const [groupValid, setGroupValid] = useState(true)
  const [selfServe, setSelfServe] = useState(() => packageData ? packageData.self_serve : true)
  const { register, handleSubmit, formState, errors } = useForm({ mode: "onChange" })
  const requestCenterOptions = resourceFormUtils.requestCenterOptions(requestCenters)
  const [selectedRequestCenterIds, setSelectedRequestCenterIds] = useState(() => (
    requestCenterOptions.filter(option => packageData?.request_center_ids && packageData.request_center_ids.indexOf(option.value) > -1)
  ))

  useEffect(() => {
    if (!isPublic) {
      setSelectedRequestCenterIds([])
    }
  }, [isPublic])

  const submitPackage = (data) => {
    if (formError()) {
      return
    }

    const formMethod = packageData ? "PUT" : "POST"
    const formUrl = packageData ? `/packages/${packageData.slug}.json` : "/packages.json"

    data.public = isPublic
    data.group_ids = groups.map(group => group.value)
    data.request_center_ids = selectedRequestCenterIds.map(requestCenter => requestCenter.value)
    data.self_serve = selfServe

    fetch(formUrl, {
      method: formMethod,
      body: JSON.stringify({ package: data }),
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'X-CSRF-Token': utils.csrfToken()
      }
    })
      .then(res => res.json())
      .then(packageData => {
        if (packageData.redirect_path) {
          window.location = packageData.redirect_path
          return
        }

        onSave(packageData)
      })
  }

  const formError = () => {
    return !groupValid
  }

  const confirmButton = <input
    className={`button is-info ${formState.isValid ? '' : 'disabled'}`}
    draggable="false"
    type="submit"
    value={packageData ? 'Save' : 'Next'} />

  const deleteButton = useMemo(() => {
    if (!packageData) {
      return null
    }

    return <a
      className="button is-danger is-inverted is-pulled-left"
      draggable="false"
      onClick={() => onDelete()}
      style={{ position: 'absolute', left: '40px' }}>Delete</a>
  }, [packageData])

  return <form onSubmit={handleSubmit(submitPackage)}>
    <Modal
      confirmButton={confirmButton}
      cancelAction={onCancel}
      deleteButton={deleteButton}
      modalCardStyle={{ overflow: 'visible' }}
      modalCardBodyStyle={{ overflow: 'visible' }}
      title={`${packageData ? 'Edit' : 'Create'} Package`}
    >
      <div className="columns">
        <div className="column">
          <TextField
            defaultValue={packageData?.name}
            errors={errors}
            label="Name"
            name="name"
            placeholder="E.g., Common security package"
            reff={register({ required: true })} />
        </div>
      </div>
      <div className="columns">
        <div className="column">
          <TextArea
            defaultValue={packageData?.description}
            errors={errors}
            label="Description"
            name="description"
            placeholder="E.g., Low level security documents &amp; SOC 2 report protected by NDA"
            reff={register()} />
        </div>
      </div>

      <div className="columns">
        <div className="column">
          <span className="control">
            <span className="is-primary pure-material-checkbox">
              <label className="pure-material-checkbox" htmlFor="package_self_serve">
                <input type="checkbox"
                  value="1"
                  checked={selfServe}
                  onChange={() => setSelfServe(!selfServe)}
                  name="package[self_serve]"
                  id="package_self_serve" />
                <span>
                  Automatically send this package when it’s requested. <a href="https://support.pima.app/packages" target="_blank">Learn More.</a>
                </span>
              </label>
            </span>
          </span>
        </div>
      </div>

      {canEditGroups && <div className="columns">
        <div className="column">
          <GroupSelector
            groupOptions={groupOptions}
            hasGroups={groups.length > 0}
            isSubmitted={formState.isSubmitted}
            onChange={groups => setGroups(groups)}
            onGroupChangeValid={valid => setGroupValid(valid)}
            resourceName="package"
            selectedGroupIds={groups} />
        </div>
      </div>}
    </Modal>
  </form>
}

export default PackageForm
