import React, { useState } from "react"

import BulkArchiveModalForm from './BulkArchiveModalForm'

const BulkArchive = ({
  selectedDocumentResourceIds
}) => {
  const [showBulkArchiveModal, setShowBulkArchiveModal] = useState(false)

  return <>
    <a
      onClick={() => setShowBulkArchiveModal(!showBulkArchiveModal)}
      style={{ color: '#d92022', fontSize: '14px', fontWeight: '600', marginLeft: '20px' }}>
      Archive
    </a>
    {showBulkArchiveModal && <BulkArchiveModalForm
      selectedDocumentResourceIds={selectedDocumentResourceIds}
      cancelAction={() => setShowBulkArchiveModal(false)} />}
  </>
}

export default BulkArchive
