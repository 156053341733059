import React, { useEffect, useMemo, useRef, useState } from "react"

import SearchBox from "components/shared/SearchBox"
import utils from 'components/utils/utils'

import AllRowSelect from "./AllRowSelect"
import DocumentResourceRow from "./DocumentResourceRow"
import EditLabel from "./EditLabel"
import LabelFilter from "../LabelFilter"
import UserPlanLimitReachBtn from "../../Plans/UserPlanLimitReachBtn"

const searchBoxRightMargin = 20

const DocumentResourceList = ({
  admins,
  adminEmails,
  documentResources: initialDocumentResources,
  isAdmin,
  labels,
  planStatus
}) => {
  const [documentResources, setDocumentResources] = useState(initialDocumentResources)
  const [labelFilterRight, setLabelFilterRight] = useState(null)
  const [selectedDocumentResourceIds, setSelectedDocumentResourceIds] = useState([])
  const [searchValue, setSearchValue] = useState(null)
  const searchBoxWrapper = useRef(null)

  const documentResourceList = useMemo(() => (
    documentResources.filter(documentResource => {
      if (!searchValue) return true
        
      return documentResource.name.toUpperCase().includes(searchValue.toUpperCase())
    }).map(documentResource => <DocumentResourceRow
      documentResource={documentResource}
      key={documentResource.slug} />)
  ), [documentResources, searchValue])

  const handleResize = () => {  
    if (searchBoxWrapper.current) {
      const newSearchBoxWrapperWidth = utils.widthOfDiv(searchBoxWrapper.current) + searchBoxRightMargin
      if (newSearchBoxWrapperWidth !== labelFilterRight) {
        setLabelFilterRight(newSearchBoxWrapperWidth)
      }
    }
  }

  useEffect(() => {
    window.addEventListener('resize', handleResize)

    handleResize()

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [handleResize])

  return <div>
    <div className="content is-title-block">
      Documents
      {planStatus?.reached && <UserPlanLimitReachBtn
        admins={admins}
        adminEmails={adminEmails}
        isAdmin={isAdmin}
        type="document" limit={planStatus.limit}
      />}
      {!planStatus?.reached && <a className="button is-primary" href="/documents/new">Add Document</a>}
    </div>
    {selectedDocumentResourceIds.length === 0 && <div className="content" style={{
      backgroundColor: '#FFFFFF',
      height: '64px',
      paddingTop: '13px',
      position: 'relative',
    }}>
      <LabelFilter
        labels={labels}
        listName="documentResourceList"
        onFilterUpdate={selectedLabelIds => {
          if (selectedLabelIds[0] === 'all') {
            setDocumentResources(initialDocumentResources)
          } else {
            setDocumentResources(
              initialDocumentResources.filter(documentResource => {
                const documentResourceLabelIds = documentResource.labels.map(label => label.id)
                return documentResourceLabelIds.filter(documentResourceLabelId => selectedLabelIds.includes(documentResourceLabelId)).length > 0
              })
            )
          }
        }}
        right={labelFilterRight} />
      {labels.length === 0 && <a href="/settings/labels" style={{ fontWeight: '600' }}>Create label to get started</a>}
      <div style={{ position: 'absolute', right: `${searchBoxRightMargin}px` }} ref={searchBoxWrapper}>
        <SearchBox onChange={value => setSearchValue(value)} onSearchExpendChange={() =>  handleResize()} />
      </div>
    </div>}
    {selectedDocumentResourceIds.length > 0 && <div
      className="content"
      style={{
        height: '64px',
        paddingLeft: '6px',
        paddingTop: '12px'
      }}>
      <EditLabel
        documentResources={documentResources}
        labels={labels}
        selectedDocumentResourceIds={selectedDocumentResourceIds} />
    </div>}

    <div className="content is-paddingless is-marginless is-resource-list">
      {documentResources.length > 0 && <table>
        <thead>
          <tr>
            <th className="has-text-weight-normal has-text-centered" style={{ height: '48px', width: '48px', borderWidth: '0 1px 1px' }}>
              <AllRowSelect
                onChange={selectedDocumentResourceIds => setSelectedDocumentResourceIds(selectedDocumentResourceIds)}
                resourceList={documentResources}
              />
            </th>
            <th className="has-text-weight-normal" style={{ borderWidth: '0 0 1px' }}>Document</th>
            <th className="has-text-weight-normal" style={{ borderWidth: '0 0 1px' }}>Labels</th>
            <th className="has-text-weight-normal" style={{ borderWidth: '0 0 1px', width: '300px' }}>Tools</th>
          </tr>
        </thead>
        <tbody>
          {documentResourceList}
        </tbody>
      </table>}
      {documentResources.length === 0 && <div className="content has-text-centered is-flex is-align-items-center is-justify-content-center">
        {initialDocumentResources.length === 0 && <>Store all your important resources here.<br />You can decide to list their name publicly to make them available on the Request center.</>}
        {initialDocumentResources.length !== 0 && <div><div>There are no documents with this label.</div><div style={{ color: '#8C9296' }}>Tip: select one or more documents and click “Edit labels” to apply labels.</div></div>}
      </div>}
    </div>
  </div >
}

export default DocumentResourceList



