import React, { useMemo } from "react"

const TextArea = ({
  defaultValue,
  errors,
  label,
  name,
  onChange,
  placeholder,
  reff,
  style = {},
}) => {
  const fieldId = useMemo(() => name.replace('[', '_').replace(']', '_'), [name])

  let detectedError = errors && errors[name]

  if (name.indexOf('[') > -1 && name.indexOf(']') > -1) {
    const brokenFieldName = name.split('[')
    const recordType = brokenFieldName[0]
    const recordField = brokenFieldName[1].replace(']', '')

    if (errors && errors[recordType] && errors[recordType][recordField]) {
      detectedError = errors[recordType][recordField]
    }
  }

  const error = useMemo(() => {
    if (!detectedError || detectedError.type != 'required') {
      return
    }

    return <span className="error">Required</span>
  }, [detectedError])

  return <div className="field">
    <div className="control">
      <label className="label" htmlFor={fieldId}>{label}</label>
      <textarea
        autoComplete="off"
        className={`input ${detectedError ? 'is-danger' : ''}`}
        defaultValue={defaultValue}
        id={fieldId}
        name={name}
        onChange={onChange}
        placeholder={placeholder}
        ref={reff}
        style={style}
        type="text">
      </textarea>
      {error}
    </div>
  </div>
}

export default TextArea
