import React from "react"

import LabelList from "../LabelList"

const LabelSelector = ({
  isAdmin,
  labels,
  onChange = (labelIds) => { },
  selectedLabelIds
}) => {
  return <>
    <label className="label" style={{ paddingBottom: '8px' }}>Choose Labels:</label>
    {labels.length > 0 && <div style={{ position: 'relative', marginLeft: '-20px' }}>
      <LabelList
        addAllOption={false}
        initialLabels={labels}
        onLabelSelectionChange={labelIds => onChange(labelIds)}
        selectedLabelIds={selectedLabelIds} />
      <br />
      <br />
    </div>}
    {labels.length == 0 && <p style={{ fontSize: '12px', marginTop: '5px' }}>Create <a href="/settings/labels">labels</a> to enable this feature</p>}
    {isAdmin && !labels.length == 0 && <p style={{ fontSize: '12px' }}><a href="/settings/labels">Add label</a></p>}
  </>
}


export default LabelSelector